import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { mapItems } from "../mapConstant";

import "./styles.scss";
import "../../styles/_base.scss";

const NavbarBurger = (props) => (
  <div
    onClick={props.toggleMenu}
    className={`navbar-burger ${props.active ? "is-active" : ""}`}
  >
    <span />
    <span />
    <span />
  </div>
);

export default class Navbar extends React.Component {
  state = {
    activeMenu: false,
  };

  toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu,
    });
  };

  render() {
    return (
      <nav id="navnav" className="navbar">
        <div className="navbar-menu-close">
          <a href="/">
            <b>YKH</b>
          </a>

          {/* <a className="navbar-item img-class">
            <img src={logo} className="logo" alt="logo" />
          </a> */}

          <NavbarBurger
            active={this.state.activeMenu}
            toggleMenu={this.toggleMenu}
          />
        </div>

        <div
          className={`navbar-menu ${this.state.activeMenu ? "is-active" : ""}`}
        >
          <ul>
            {mapItems.map((item, index) => {
              return (
                <li
                  alt={item.alt}
                  title={item.title}
                  onClick={this.toggleMenu}
                  className="header-item"
                  key={index}
                >
                  <Link to={item.link} className="header-item" key={index}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    );
  }
}
