import React, { PureComponent } from "react";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Carousel from "react-grid-carousel";

import CarouselSlider from "../../components/CarouselSlider/CarouselSlider";
import Swiper from "swiper";

import "../../styles/main.scss";
import "../../styles/_base.scss";
import "./styles.scss";

//#region Lorry Crane Cargo Images
import cargo0 from "../../styles/sources/cranes/crane/image0.jpeg";
import cargo2 from "../../styles/sources/cranes/crane/image2.jpeg";
import cargo3 from "../../styles/sources/cranes/crane/image3.jpeg";
import cargo4 from "../../styles/sources/cranes/crane/image4.jpeg";
import cargo5 from "../../styles/sources/cranes/crane/image5.jpeg";
import cargo6 from "../../styles/sources/cranes/crane/image6.jpeg";
import cargo7 from "../../styles/sources/cranes/crane/image7.jpeg";
import cargo8 from "../../styles/sources/cranes/crane/image8.jpeg";
import cargo9 from "../../styles/sources/cranes/crane/image9.jpeg";
import cargo10 from "../../styles/sources/cranes/crane/image10.jpeg";
//#endregion

//#region Lorry Crane Cargo shorts
import CargoVideo from "../../styles/sources/cranes/shorts/Video.MOV";
import CargoVideo1 from "../../styles/sources/cranes/shorts/Video1.MOV";
//#endregion

//#region Lorry Crane Platform Image
import CargoPlatformBlue from "../../styles/sources/cranes/platform/blue/image.png";
import CargoPlatformBlue1 from "../../styles/sources/cranes/platform/blue/image1.png";
import CargoPlatform0 from "../../styles/sources/cranes/platform/image0.jpeg";
import CargoPlatform2 from "../../styles/sources/cranes/platform/image2.jpeg";
import CargoPlatform3 from "../../styles/sources/cranes/platform/image3.jpeg";
import CargoPlatform4 from "../../styles/sources/cranes/platform/image4.jpeg";
import CargoPlatform5 from "../../styles/sources/cranes/platform/image5.jpeg";
import CargoPlatform6 from "../../styles/sources/cranes/platform/image6.jpeg";
import CargoPlatform7 from "../../styles/sources/cranes/platform/image7.jpeg";
import CargoPlatform8 from "../../styles/sources/cranes/platform/image8.jpeg";
import CargoPlatform9 from "../../styles/sources/cranes/platform/image9.jpeg";
import CargoPlatform10 from "../../styles/sources/cranes/platform/image10.jpeg";
//#endregion

//#region Show Case Image
import ShowCase1 from "../../styles/sources/cranes/showcase/image0.jpeg";
import ShowCase2 from "../../styles/sources/cranes/showcase/image2.jpeg";
import ShowCase3 from "../../styles/sources/cranes/showcase/image3.jpeg";
import ShowCase4 from "../../styles/sources/cranes/showcase/image4.jpeg";
import ShowCase5 from "../../styles/sources/cranes/showcase/image5.jpeg";
import ShowCase6 from "../../styles/sources/cranes/showcase/image6.jpeg";
import ShowCase7 from "../../styles/sources/cranes/showcase/image7.jpeg";
import ShowCase8 from "../../styles/sources/cranes/showcase/image8.jpeg";
import ShowCase9 from "../../styles/sources/cranes/showcase/image9.jpeg";
import ShowCase10 from "../../styles/sources/cranes/showcase/image10.jpeg";
import ShowCase11 from "../../styles/sources/cranes/showcase/image11.jpeg";
import ShowCase12 from "../../styles/sources/cranes/showcase/image12.jpeg";
import ShowCase13 from "../../styles/sources/cranes/showcase/image13.jpeg";
import ShowCase14 from "../../styles/sources/cranes/showcase/image14.jpeg";
import ShowCase15 from "../../styles/sources/cranes/showcase/image15.jpeg";
import ShowCase16 from "../../styles/sources/cranes/showcase/image16.jpeg";
import ShowCase17 from "../../styles/sources/cranes/showcase/image17.jpeg";
import ShowCase18 from "../../styles/sources/cranes/showcase/image18.jpeg";
import ShowCase19 from "../../styles/sources/cranes/showcase/image19.jpeg";
import ShowCase20 from "../../styles/sources/cranes/showcase/image20.jpeg";
import ShowCase22 from "../../styles/sources/cranes/showcase/image21.jpeg";
import ShowCase21 from "../../styles/sources/cranes/showcase/image22.jpeg";
//#endregion

import Main1 from "../../styles/sources/trailers/lorry/main1.jpeg";
import Main2 from "../../styles/sources/trailers/lorry/main2.jpeg";
import Main3 from "../../styles/sources/trailers/lorry/main3.jpeg";

const imgSliderCargo = [
  {
    title: "cargo1",
    alt: "cargo1",
    image: cargo0,
  },
  {
    title: "cargo2",
    alt: "cargo2",
    image: cargo2,
  },
  {
    title: "cargo3",
    alt: "cargo3",
    image: cargo3,
  },
  {
    title: "cargo4",
    alt: "cargo4",
    image: cargo4,
  },
  {
    title: "cargo5",
    alt: "cargo5",
    image: cargo5,
  },
  {
    title: "cargo6",
    alt: "cargo6",
    image: cargo6,
  },
  {
    title: "cargo7",
    alt: "cargo7",
    image: cargo7,
  },
  {
    title: "cargo8",
    alt: "cargo8",
    image: cargo8,
  },
  {
    title: "cargo9",
    alt: "cargo9",
    image: cargo9,
  },
  {
    title: "cargo10",
    alt: "cargo10",
    image: cargo10,
  },
];

const imgSliderCargoPlatform = [
  {
    title: "CargoPlatform0",
    alt: "CargoPlatform0",
    image: CargoPlatform0,
  },
  {
    title: "CargoPlatform2",
    alt: "CargoPlatform2",
    image: CargoPlatform2,
  },
  {
    title: "CargoPlatform3",
    alt: "CargoPlatform3",
    image: CargoPlatform3,
  },
  {
    title: "CargoPlatform4",
    alt: "CargoPlatform4",
    image: CargoPlatform4,
  },
  {
    title: "CargoPlatform5",
    alt: "CargoPlatform5",
    image: CargoPlatform5,
  },
  {
    title: "CargoPlatform6",
    alt: "CargoPlatform6",
    image: CargoPlatform6,
  },
  {
    title: "CargoPlatform7",
    alt: "CargoPlatform7",
    image: CargoPlatform7,
  },
  {
    title: "CargoPlatform8",
    alt: "CargoPlatform8",
    image: CargoPlatform8,
  },
  {
    title: "CargoPlatform9",
    alt: "CargoPlatform9",
    image: CargoPlatform9,
  },
  {
    title: "CargoPlatform10",
    alt: "CargoPlatform10",
    image: CargoPlatform10,
  },
];

const imgSliderCargoPlatformBlue = [
  {
    title: "Blue print 1",
    alt: "CargoPlatformBlue",
    image: CargoPlatformBlue,
  },
  {
    title: "Blue print 2",
    alt: "CargoPlatformBlue1",
    image: CargoPlatformBlue1,
  },
];

const imgSliderShowCase = [
  {
    title: "ShowCase1",
    alt: "ShowCase1",
    image: ShowCase1,
  },
  {
    title: "ShowCase2",
    alt: "ShowCase2",
    image: ShowCase2,
  },
  {
    title: "ShowCase3",
    alt: "ShowCase3",
    image: ShowCase3,
  },
  {
    title: "ShowCase4",
    alt: "ShowCase4",
    image: ShowCase4,
  },
  {
    title: "ShowCase5",
    alt: "ShowCase5",
    image: ShowCase5,
  },
  {
    title: "ShowCase6",
    alt: "ShowCase6",
    image: ShowCase6,
  },
  {
    title: "ShowCase7",
    alt: "ShowCase7",
    image: ShowCase7,
  },
  {
    title: "ShowCase8",
    alt: "ShowCase8",
    image: ShowCase8,
  },
  {
    title: "ShowCase9",
    alt: "ShowCase9",
    image: ShowCase9,
  },
  {
    title: "ShowCase10",
    alt: "ShowCase10",
    image: ShowCase10,
  },
  {
    title: "ShowCase11",
    alt: "ShowCase11",
    image: ShowCase11,
  },
  {
    title: "ShowCase12",
    alt: "ShowCase12",
    image: ShowCase12,
  },
  {
    title: "ShowCase13",
    alt: "ShowCase13",
    image: ShowCase13,
  },
  {
    title: "ShowCase14",
    alt: "ShowCase14",
    image: ShowCase14,
  },
  {
    title: "ShowCase15",
    alt: "ShowCase15",
    image: ShowCase15,
  },
  {
    title: "ShowCase16",
    alt: "ShowCase16",
    image: ShowCase16,
  },
  {
    title: "ShowCase17",
    alt: "ShowCase17",
    image: ShowCase17,
  },
  {
    title: "ShowCase18",
    alt: "ShowCase18",
    image: ShowCase18,
  },
  {
    title: "ShowCase19",
    alt: "ShowCase19",
    image: ShowCase19,
  },
  {
    title: "ShowCase20",
    alt: "ShowCase20",
    image: ShowCase20,
  },
  {
    title: "ShowCase21",
    alt: "ShowCase21",
    image: ShowCase21,
  },
  {
    title: "ShowCase22",
    alt: "ShowCase22",
    image: ShowCase22,
  },
];

const main = [
  {
    src: Main1,
  },
  {
    src: Main2,
  },
  {
    src: Main3,
  },
];

export default class Cranes extends PureComponent {
  render() {
    return (
      <React.Fragment>
        <Container className="wrapper">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={"/"} className="header-item">
                  Home
                </Link>
              </BreadcrumbItem>

              <BreadcrumbItem>Cranes</BreadcrumbItem>
            </Breadcrumb>
          </div>

          <br />
          <br />
          <br />

          <Row>
            <Col lg={1}></Col>

            <Col lg={11}>
              <p style={{ textAlign: "left", fontSize: "2.5rem" }}>
                <b>
                  <i>Cranes</i>
                </b>
              </p>
            </Col>

            <Col lg={1}></Col>

            <Col lg={10}>
              <p style={{ textAlign: "justify", fontSize: "14px" }}>
                Our company offers a comprehensive solution for{" "}
                <b>heavy-duty transportation</b> with two types of{" "}
                <b>lorry crane. </b>
                Lorry crane <b>Cargo</b> and <b>Platform</b> that are capable of
                handling loads ranging from <b>8 tons</b> to an impressive
                maximum of <b>25 tons</b>. These versatile <b>lorries</b>,
                ranging from <b>24ft</b> to <b>30ft</b> in length, are equipped
                with powerful <b>cranes</b> capable of lifting weights up to{" "}
                <b>18 tons</b>, providing the utmost efficiency and reliability
                for a diverse range of industrial and commercial applications.
              </p>
            </Col>

            <Col lg={1}></Col>
          </Row>

          <Row>
            <Col lg={1}></Col>

            <Col lg={11}>
              <br />

              <p
                style={{
                  textAlign: "left",
                  marginBottom: "0",
                  textDecoration: "underline",
                }}
                className="p-wrapper"
              >
                <i>
                  <b>3 Ton</b> Lorry Crane
                </i>
              </p>
            </Col>
          </Row>

          <Row className="row-desktop-wrapper">
            <Col lg={1}></Col>

            {main.map((item, index) => {
              return (
                <Col sm={12} lg={3} key={index} style={{ marginTop: "20px" }}>
                  <img src={item.src} width="100%" height="100%" />
                </Col>
              );
            })}
          </Row>

          <Row>
            <Col lg={2}></Col>

            <Col lg={8}>
              <p style={{ textAlign: "justify", fontSize: "15px" }}>
                <br />
                Our versatile and efficient <b>lorry</b>, equipped with a
                powerful <b>crane</b>, is capable of handling loads ranging from{" "}
                <b>1 ton</b> to <b>7 tons</b>, making it the ideal choice for{" "}
                <b>cargo</b> and <b>platform</b> transportation needs. With a
                length of <b>21ft</b> and a crane capable of lifting up to{" "}
                <b>3 tons</b>, this lorry is well-equipped to handle a wide
                range of tasks, ensuring seamless and reliable operations for
                various industrial and commercial applications.
              </p>
            </Col>

            <Col lg={2}></Col>
          </Row>

          <hr
            style={{
              width: "40vw",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />

          <Row>
            <Col lg={1}></Col>

            <Col lg={11}>
              <br />

              <p
                style={{
                  textAlign: "left",
                  marginBottom: "20px",
                  textDecoration: "underline",
                }}
                className="p-wrapper"
              >
                <i>Lorry Crane</i>
              </p>
            </Col>
          </Row>

          <Row className="row-desktop-wrapper">
            <Col lg={1}></Col>

            <Col md={10} lg={10}>
              <Carousel
                cols={2}
                rows={2}
                gap={10}
                loop={true}
                showDots={true}
                scrollSnap={true}
                responsiveLayout={[
                  { breakpoint: 499, autoplay: 2000, loop: true },
                ]}
              >
                {imgSliderCargo.map((item, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <img
                        width="100%"
                        height="200px"
                        alt={item.alt}
                        src={item.image}
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>

              <br />
            </Col>

            <Col md={1} lg={2}></Col>

            <Col lg={8}>
              <div style={{ textAlign: "justify", fontSize: "15px" }}>
                <p>
                  Our state-of-the-art <b>Lorry Crane</b> with a robust lifting
                  capacity of <b>30 tons</b> is engineered to tackle heavy-duty
                  tasks with unparalleled efficiency and precision. The powerful{" "}
                  crane, <b>Rhino BS1800</b>, can handle loads of up to{" "}
                  <b>30 tons</b>, providing exceptional versatility for various
                  industrial and commercial applications.
                  <br />
                  <br />
                  With a generous lorry length of <b>up to 30ft</b> and a weight
                  capacity of <b>up to 12ft</b>, our lorry crane offers ample
                  space to accommodate a wide range of cargo or platform
                  requirements. The crane's impressive reach of{" "}
                  <b>up to 52ft</b> with <b>8 booms</b> ensures that it can
                  handle tasks that require extended reach and precision.
                </p>
              </div>
            </Col>
          </Row>

          <hr
            style={{
              width: "40vw",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />

          <Row>
            <Col lg={1}></Col>

            <Col lg={11}>
              <br />

              <p
                style={{
                  textAlign: "left",
                  marginBottom: "20px",
                  textDecoration: "underline",
                }}
                className="p-wrapper"
              >
                <i>Lorry Crane Platform</i>
              </p>
            </Col>
          </Row>

          <Row className="row-desktop-wrapper">
            <Col lg={1}></Col>

            <Col md={10} lg={10}>
              <Carousel
                cols={2}
                rows={2}
                gap={10}
                loop={true}
                showDots={true}
                scrollSnap={true}
                responsiveLayout={[
                  { breakpoint: 499, autoplay: 2000, loop: true },
                ]}
              >
                {imgSliderCargoPlatform.map((item, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <img
                        width="100%"
                        height="200px"
                        alt={item.alt}
                        src={item.image}
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>

              <br />
            </Col>

            <Col md={1} lg={2}></Col>

            <Col lg={8}>
              <div style={{ textAlign: "justify", fontSize: "15px" }}>
                <p>
                  Our cutting-edge <b>Lorry Crane</b>, the <b>RhinoBS1450</b>{" "}
                  model, is designed to excel in heavy-duty lifting tasks,
                  boasting an impressive lifting capacity of <b>25 tons</b>.
                  <br />
                  <br />
                  Versatility is a key feature of this lorry crane, as it can be
                  utilized for both <b>cargo</b> and <b>platform</b> operations.
                  Whether you need to transport goods or handle heavy equipment,
                  our lorry crane is equipped to tackle the task with ease and
                  precision.
                  <br />
                  <br />
                  With a generous body length of <b>up to 30ft</b> and a weight
                  capacity of <b>up to 12ft</b>, our lorry crane provides ample
                  space and stability for accommodating various types of cargo
                  or platform setups.
                </p>
              </div>
            </Col>

            <Row>
              <Col lg={2}></Col>

              <Col lg={2}>
                <p
                  style={{
                    textAlign: "left",
                    marginBottom: "20px",
                    textDecoration: "underline",
                    fontSize: "16px",
                  }}
                  className="p-wrapper"
                >
                  <i>Blue Prints</i>
                </p>
              </Col>
            </Row>
          </Row>

          <Row className="row-desktop-wrapper">
            <Col lg={2}></Col>

            <Col md={10} lg={8}>
              <Carousel
                cols={2}
                rows={1}
                gap={10}
                loop={true}
                showDots={true}
                scrollSnap={true}
                responsiveLayout={[
                  { breakpoint: 499, autoplay: 2000, loop: true },
                ]}
              >
                {imgSliderCargoPlatformBlue.map((item, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <img
                        width="100%"
                        height="200px"
                        alt={item.alt}
                        src={item.image}
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>

              <br />
            </Col>
          </Row>

          <hr
            style={{
              width: "40vw",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />

          <Row>
            <Col lg={1}></Col>

            <Col lg={11}>
              <br />

              <p
                style={{
                  textAlign: "left",
                  marginBottom: "20px",
                  textDecoration: "underline",
                }}
                className="p-wrapper"
              >
                <i>Gallery Show Cases</i>
              </p>
            </Col>
          </Row>

          <Row className="row-desktop-wrapper">
            <Col lg={1}></Col>

            <Col md={10} lg={10}>
              <Carousel
                cols={3}
                rows={3}
                gap={10}
                loop={true}
                showDots={true}
                scrollSnap={true}
                responsiveLayout={[
                  { breakpoint: 499, autoplay: 2000, loop: true },
                ]}
              >
                {imgSliderShowCase.map((item, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <img
                        width="100%"
                        height="200px"
                        alt={item.alt}
                        src={item.image}
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>

              <br />
            </Col>
          </Row>

          <hr
            style={{
              width: "40vw",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Container>
      </React.Fragment>

      // <div className="wrapper cranes">
      //   <h1>Cranes</h1>

      //   <section className="cranes-cargo">
      //     <h5>Lorry Cranes Cargo</h5>

      //     <CarouselSlider data={imgSliderCargo} speed={3500} />

      //     <section className="cranes-cargo-details">
      //       <p>
      //         - Crane model name: <b>Rhino BS1800</b>
      //       </p>

      //       <p>
      //         - Maximum carry weight available up to{" "}
      //         <span className="underline">30 tons</span>
      //       </p>
      //       <p>
      //         - Lorry Length available up to{" "}
      //         <span className="underline">30ft</span>
      //       </p>
      //       <p>
      //         - Lorry Weight available up to{" "}
      //         <span className="underline">12ft</span>
      //       </p>
      //       <p>
      //         - Crane length available up to{" "}
      //         <span className="underline">52ft 8 booms</span>
      //       </p>
      //     </section>
      //   </section>

      //   <br />
      //   <hr />
      //   <br />

      //   <section className="cranes-cargo-platform">
      //     <h5>Lorry Cranes Cargo / Platform</h5>

      //     <CarouselSlider data={imgSliderCargoPlatform} speed={3500} />

      //     <section className="cranes-cargo-platform-details">
      //       <p>
      //         - Crane model name: <b>Rhino BS1450</b>
      //       </p>

      //       <p>
      //         - Maximum carry weight available up to{" "}
      //         <span className="underline">25 tons</span>
      //       </p>
      //       <p>
      //         - Lorry Length available up to{" "}
      //         <span className="underline">30ft</span>
      //       </p>
      //       <p>
      //         - Lorry Weight available up to{" "}
      //         <span className="underline">12ft</span>
      //       </p>
      //       <p>
      //         - Crane length available up to{" "}
      //         <span className="underline">52ft 8 booms</span>
      //       </p>
      //     </section>

      //     <br />

      //     <p className="blue-print">Blue Prints</p>

      //     <CarouselSlider data={imgSliderCargoPlatformBlue} speed={5000} />

      //     <section className="cranes-cargo-platform-shorts">
      //       <p className="shorts">Shorts</p>

      //       <video width="100%" controls>
      //         <source src={CargoVideo} type="video/mp4" />
      //       </video>

      //       <video width="100%" controls>
      //         <source src={CargoVideo1} type="video/mp4" />
      //       </video>
      //     </section>
      //   </section>
      // </div>
    );
  }
}
