import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";
import WhatsAppIcon from "../../styles/sources/FloatIcons/whatsapp.png";
import PhoneIcon from "../../styles/sources/FloatIcons/telephone.png";

const FloatIcons = () => {
  return (
    <div className="icons-wrapper">
      <Link to="https://wa.link/hx7xvg" target="_blank">
        <img src={WhatsAppIcon} alt="whatsapp-icon" className="whatsapp" />
      </Link>

      <a href="tel:60362610054" target="_blank" rel="noopener">
        <img src={PhoneIcon} alt="telephone-icon" className="telephone" />
      </a>
    </div>
  );
};

export default FloatIcons;
