import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Label,
  Row,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  UncontrolledCarousel,
  List,
} from "reactstrap";
import Carousel from "react-grid-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss";
import "../../styles/main.scss";
import "../../styles/_base.scss";

import Main1 from "../../styles/sources/trailers/lorry/main1.jpeg";
import Main2 from "../../styles/sources/trailers/lorry/main2.jpeg";
import Main3 from "../../styles/sources/trailers/lorry/main3.jpeg";

import Cargo1 from "../../styles/sources/trailers/lorry/image0.jpeg";
import Cargo2 from "../../styles/sources/trailers/lorry/image2.jpeg";
import Cargo3 from "../../styles/sources/trailers/lorry/image3.jpeg";
import Cargo4 from "../../styles/sources/trailers/lorry/image4.jpeg";
import Cargo5 from "../../styles/sources/trailers/lorry/image5.jpeg";
import Cargo6 from "../../styles/sources/trailers/lorry/image6.jpeg";
import Cargo7 from "../../styles/sources/trailers/lorry/image7.jpeg";
import Cargo8 from "../../styles/sources/trailers/lorry/image8.jpeg";
import Cargo9 from "../../styles/sources/trailers/lorry/image9.jpeg";

const Trailers = (props) => {
  const main = [
    {
      src: Main1,
    },
    {
      src: Main2,
    },
    {
      src: Main3,
    },
  ];

  const items = [
    {
      src: Cargo1,
    },
    {
      src: Cargo2,
    },
    {
      src: Cargo3,
    },
    {
      src: Cargo4,
    },
    {
      src: Cargo5,
    },
    {
      src: Cargo6,
    },
    {
      src: Cargo7,
    },
    {
      src: Cargo8,
    },
  ];

  return (
    <React.Fragment>
      <Container className="wrapper">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={"/"} className="header-item">
                Home
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem>Trailers</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <br />
        <br />
        <br />

        <Row>
          <Col lg={1}></Col>

          <Col lg={11}>
            <p style={{ textAlign: "left", fontSize: "2.5rem" }}>
              <b>
                <i>Trailers</i>
              </b>
            </p>
          </Col>

          <Col lg={1}></Col>

          <Col lg={10}>
            <p style={{ textAlign: "justify", fontSize: "14px" }}>
              Our highly durable and robust trailers are engineered and
              constructed with cutting-edge materials and design innovations,
              enabling them to effortlessly withstand an impressive range of
              load weights, spanning from <b>20 tons</b> to an incredible{" "}
              <b>80 tons</b>, making them the ultimate heavy-duty hauling
              solution for a diverse array of industrial and commercial
              applications.
            </p>
          </Col>

          <Col lg={1}></Col>
        </Row>

        {/* <Row>
          <Col lg={1}></Col>

          <Col lg={11}>
            <br />

            <p
              style={{
                textAlign: "left",
                marginBottom: "0",
                textDecoration: "underline",
              }}
              className="p-wrapper"
            >
              <i>Crane Trailers</i>
            </p>
          </Col>
        </Row>

        <Row className="row-desktop-wrapper">
          <Col lg={1}></Col>

          {main.map((item, index) => {
            return (
              <Col sm={12} lg={3} key={index} style={{ marginTop: "20px" }}>
                <img src={item.src} width="100%" height="100%" />
              </Col>
            );
          })}
        </Row>

        <Row>
          <Col lg={2}></Col>

          <Col lg={8}>
            <p style={{ textAlign: "justify", fontSize: "15px" }}>
              <br />
              Our versatile and efficient <b>lorry</b>, equipped with a powerful{" "}
              <b>crane</b>, is capable of handling loads ranging from{" "}
              <b>1 ton</b> to <b>7 tons</b>, making it the ideal choice for{" "}
              <b>cargo</b> and <b>platform</b> transportation needs. With a
              length of <b>21ft</b> and a crane capable of lifting up to{" "}
              <b>3 tons</b>, this lorry is well-equipped to handle a wide range
              of tasks, ensuring seamless and reliable operations for various
              industrial and commercial applications.
            </p>
          </Col>

          <Col lg={2}></Col>
        </Row>

        <br />
        <br /> */}

        <Row>
          <Col lg={1}></Col>

          <Col lg={11}>
            <p
              style={{
                textAlign: "left",
                marginBottom: "20px",
                textDecoration: "underline",
              }}
              className="p-wrapper"
            >
              <i>Trailers</i>
            </p>
          </Col>

          <Col md={1} lg={1}></Col>

          <Col md={10} lg={10}>
            <Carousel
              cols={2}
              rows={2}
              gap={10}
              loop={true}
              showDots={true}
              scrollSnap={true}
              responsiveLayout={[
                { breakpoint: 499, autoplay: 2000, loop: true },
              ]}
            >
              {items.map((item, index) => {
                return (
                  <Carousel.Item key={index}>
                    <img width="100%" height="250vh" src={item.src} />
                  </Carousel.Item>
                );
              })}
            </Carousel>

            <br />
          </Col>

          <Col md={1} lg={2}></Col>

          <Col lg={8}>
            <div style={{ textAlign: "justify", fontSize: "15px" }}>
              <p>
                As a customer-focused and forward-thinking company, we take
                immense pride in offering our valued clients a comprehensive
                selection of top-of-the-line <b>trailers</b>, encompassing two
                distinct and highly functional types to cater to diverse
                transportation needs. These exceptional trailer types include:
              </p>

              {/* <hr
                style={{
                  width: "40vw",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              /> */}

              <p style={{ fontSize: "14px" }}>
                <FontAwesomeIcon
                  icon={faHandPointRight}
                  beat
                  style={{ color: "#000000" }}
                />{" "}
                <b>Cargo Lorry</b>: Designed and engineered for optimal
                efficiency and versatility, our <b>Cargo Lorries</b> boast
                spacious and well-organized cargo areas, allowing for secure and
                seamless transportation of various goods and commodities.
                Whether you need to transport heavy machinery, palletized goods,
                or other bulky items, our <b>Cargo Lorries</b> are specifically
                crafted to handle the task with utmost ease and reliability.
              </p>

              <p style={{ fontSize: "14px" }}>
                <FontAwesomeIcon
                  icon={faHandPointRight}
                  beat
                  style={{ color: "#000000" }}
                />{" "}
                <b>Platform Lorry</b>: Recognizing the unique requirements of
                certain industries, we have meticulously designed our{" "}
                <b>Platform Lorries</b> to serve as the ultimate solution for
                transporting oversized or irregularly shaped loads. Featuring a
                flat and expansive platform deck, our <b>Platform Lorries</b>{" "}
                offer the flexibility and stability necessary to carry large
                machinery, construction materials, and other non-standard cargo,
                ensuring safe and efficient transportation for your specific
                needs.
              </p>

              <hr
                style={{
                  width: "40vw",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </div>
          </Col>

          <Col md={1} lg={2}></Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Trailers;
