export const mapItems = [
  { title: "Home", alt: "home", link: "/" },
  { title: "Cranes", alt: "cranes", link: "/Cranes" },
  { title: "Trailers", alt: "trailes", link: "/Trailers" },
  { title: "About Us", alt: "about", link: "/About" },
  { title: "Contact Us", alt: "contact", link: "/ContactUs" },
  // { title: "Book Now", alt: "book", link: "/BookNow" },
];

export default {
  mapItems,
};
