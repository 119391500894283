import "./styles.scss";
import "../../styles/_base.scss";
import "../../styles/main.scss";

import React, { PureComponent } from "react";
import axios from "axios";

const formItem = [
  {
    type: "text",
    placeholder: "Company Name",
    fieldName: "CompanyName",
    className: "form-booking",
  },
  {
    type: "email",
    placeholder: "Email Address",
    fieldName: "EmailAddress",
    className: "form-booking",
  },
  {
    type: "hidden",
    placeholder: "_template",
    fieldName: "_template",
    value: "table",
    className: "form-booking",
  },
];

const formOptionLocation = [
  {
    value: "",
    selected: "selected",
    disabled: "disabled",
    title: "Site Location",
  },
  {
    value: "Klang Valley",
    title: "Klang Valley",
  },
  {
    value: "Kuala Lumpur",
    title: "Kuala Lumpur",
  },
  {
    value: "Others",
    title: "Others",
  },
];

const formRadioDuration = [
  {
    type: "radio",
    fieldName: "Duration",
    value: "Daily",
  },
  {
    type: "radio",
    fieldName: "Duration",
    value: "Weekly",
  },
  {
    type: "radio",
    fieldName: "Duration",
    value: "Monthly",
  },
];

const formDate = [
  {
    title: "Start Date",
    type: "date",
    placeholder: "dd/mm/yy",
    fieldName: "StartDate",
    className: "form-booking",
  },
  {
    title: "End Date",
    type: "date",
    placeholder: "dd/mm/yy",
    fieldName: "EndDate",
    className: "form-booking",
  },
];

const formOptionTransport = [
  {
    value: "",
    selected: "selected",
    disabled: "disabled",
    title: "Select Transport",
  },
  {
    value: "1",
    title: "1",
  },
  {
    value: "2",
    title: "2",
  },
  {
    value: "3",
    title: "3",
  },
];

export default class Booking extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      CompanyName: "",
      EmailAddress: "",
      ContactNo: "",
      SiteLocation: "",
      Duration: "",
      StartDate: "",
      EndDate: "",
      Transport: "",
      WorkWeight: "",
    };
  }

  handleHtmlControlChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setDuration(event) {
    console.log(event.target.value);
  }

  loadingRender() {
    console.log("loading......");
  }

  async postForm(event) {
    console.log(this.state);

    event.preventDefault();

    let promise = new Promise((resolve, reject) => {
      axios.defaults.headers.post["Content-Type"] = "application/json";
      axios
        .post("https://formsubmit.co/ajax/s.chong590@gmail.com", this.state)
        .then((response) => {
          console.log(response);

          if (response.status === 200) {
            resolve(response.status);
          }
        })
        .catch((error) => {
          console.log(error);

          if (error.status !== 200) {
            reject(error.status);
          }
        });
    });

    let result = await promise;

    if (result === 200) {
      console.log(result);
    } else {
      console.log(result);
    }

    // axios.defaults.headers.post["Content-Type"] = "application/json";
    // axios
    //   .post("https://formsubmit.co/", this.state)
    //   .then((response) => {
    //     console.log(response);

    //     // if (response.status === 200) {
    //     //   window.location =
    //     //     "https://ykhttsb.com/ThankYou?isContact=N&isBooking=Y";
    //     // }
    //   })
    //   .catch((error) => {
    //     console.log(error);

    //     // if (error.status !== 200) {
    //     //   window.location =
    //     //     "https://ykhttsb.com/ThankYou?isBooking=Failed&" + error;
    //     // }
    //   });
  }

  handleSubmit = (event) => {
    // console.log(this.state);

    this.postForm(event);
  };

  render() {
    const {
      CompanyName,
      EmailAddress,
      ContactNo,
      SiteLocation,
      Duration,
      StartDate,
      EndDate,
      Transport,
      WorkWeight,
    } = this.state;

    return (
      <div className="wrapper booking">
        <h1>
          <i>Coming Soon..</i>
        </h1>

        {/* <div className="form-wrapper">
          <form onSubmit={this.handleSubmit}>
            <h3>Booking Form</h3>

            {formItem.map((item, index) => {
              return (
                <input
                  key={index}
                  type={item.type}
                  placeholder={item.placeholder}
                  name={item.fieldName}
                  value={item.value}
                  className={item.className}
                  onChange={this.handleHtmlControlChange}
                  // required
                />
              );
            })}

            <input
              type="number"
              placeholder="Contact No"
              name="ContactNo"
              className="form-booking"
              onChange={this.handleHtmlControlChange}
              onKeyDown={(e) =>
                (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
              }
            />

            <select
              name="SiteLocation"
              value={SiteLocation}
              onChange={this.handleHtmlControlChange}
              // required
            >
              {formOptionLocation.map((item, index) => {
                return (
                  <option
                    key={index}
                    value={item.value}
                    defaultValue={item.selected}
                    disabled={item.disabled}
                  >
                    {item.title}
                  </option>
                );
              })}
            </select>

            <p>Duration: </p>
            <div className="radio-wrapper">
              {formRadioDuration.map((item, index) => {
                return (
                  <span
                    key={index}
                    onChange={(event) => this.setDuration(event)}
                  >
                    <input
                      type={item.type}
                      name={item.fieldName}
                      value={item.value}
                      className="form-booking"
                      onChange={this.handleHtmlControlChange}
                      // required
                    />
                    {item.value}
                  </span>
                );
              })}
            </div>

            <div className="date-wrapper">
              {formDate.map((item, index) => {
                return (
                  <span key={index}>
                    <p>{item.title}</p>
                    <input
                      type={item.type}
                      placeholder={item.placeholder}
                      name={item.fieldName}
                      value={item.value}
                      className={item.className}
                      onChange={this.handleHtmlControlChange}
                      // required
                    />
                  </span>
                );
              })}
            </div>

            <select
              name="Transport"
              value={Transport}
              onChange={this.handleHtmlControlChange}
              // required
            >
              {formOptionTransport.map((item, index) => {
                return (
                  <option
                    key={index}
                    value={item.value}
                    defaultValue={item.selected}
                    disabled={item.disabled}
                  >
                    {item.title}
                  </option>
                );
              })}
            </select>

            <input
              type="number"
              placeholder="Estimated Work Weight (KG)"
              name="WorkWeight"
              className="form-booking"
              onChange={this.handleHtmlControlChange}
              onKeyDown={(e) =>
                (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
              }
            />

            <button type="submit" className="btn">
              Submit
            </button>
          </form>
        </div> */}
      </div>
    );
  }
}
