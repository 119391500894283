import React, { PureComponent } from "react";
import { Route, Link } from "react-router-dom";

import { mapItems } from "../mapConstant";

import "./styles.scss";
import "../../styles/_base.scss";

export default class Footer extends PureComponent {
  state = {
    year: new Date().getFullYear(),
  };

  componentDidMount() {
    this.setState({ year: new Date().getFullYear() });
  }

  render() {
    return (
      <div className="footer">
        <div className="footer-content">
          <div className="sitemap">
            <br />
            <h4>Sitemap</h4>

            {mapItems.map((item, index) => {
              return (
                <Link
                  to={item.link}
                  title={item.title}
                  className="item-link"
                  key={index}
                  style={{ textTransform: "capitalize" }}
                >
                  <br className="spacer" /> {item.title} <br />
                </Link>
              );
            })}
          </div>

          <div className="social">
            <br />
            <h4>Social Media</h4>

            <a
              href="https://www.facebook.com/Yap-Kong-Heng-Trading-And-Transport-SB-104717448471338/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://img.icons8.com/ios-filled/50/000000/facebook--v1.png"
                alt="facebook"
              />
            </a>

            <a
              href="https://www.instagram.com/yapkonghengtradingandtransport/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://img.icons8.com/ios/50/000000/instagram-new--v1.png"
                alt="instagram"
              />
            </a>
          </div>

          <div className="details">
            <br />
            <h4>Contact</h4>
            <p>
              +603 - 6261 0054 <br /> +6017 - 866 8189
            </p>

            <p>Fax: +603 - 6277 3735</p>

            <h4>Address</h4>
            <p>
              No. 42, Jalan Kiara SD 11/6, <br /> Bandar Sri Damansara, <br />
              Petaling Jaya, 52200 Selangor.
            </p>

            <a
              href="https://ul.waze.com/ul?place=ChIJ0fBwB1dPzDERV4cnB4L1Res&ll=3.18936480%2C101.60674380&navigate=yes&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/732/732141.png"
                alt="waze"
              />
            </a>

            <a
              href="https://goo.gl/maps/mW7s65q1MSHxhFbh6"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://img.icons8.com/ios-filled/50/000000/google-maps-new.png"
                alt="gMap"
              />
            </a>

            <br />
            <br />
          </div>
        </div>

        <hr
          className="hr-footer"
          style={{ width: "50vw", marginLeft: "auto", marginRight: "auto" }}
        />

        <br />

        <h5>
          Yap Kong Heng Trading & Transport Sdn Bhd {this.state.year} © All
          rights reserved.
        </h5>

        <br />
      </div>
    );
  }
}
