import "./styles.scss";
import "../../styles/_base.scss";
import "../../styles/main.scss";

import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";

const formItem = [
  {
    type: "text",
    placeholder: "Full Name",
    fieldName: "name",
  },
  {
    type: "email",
    placeholder: "Email Address",
    fieldName: "email",
  },
  {
    type: "text",
    placeholder: "Contact No",
    fieldName: "contactno",
  },
  {
    type: "hidden",
    placeholder: "_next",
    fieldName: "_next",
    value: "https://ykhttsb.com/ThankYou?isContact=Y&isBooking=N",
  },
  {
    type: "hidden",
    placeholder: "_template",
    fieldName: "_template",
    value: "table",
  },
];

const Contact = () => {
  const form = useRef();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [isChecked, setIsChecked] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormSubmittedToClient, setIsFormSubmittedToClient] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    const toastId = toast.loading("Sending email...");

    // setIsFormSubmitted(true);
    // setIsFormSubmittedToClient(true);

    // //email to admin
    emailjs
      .sendForm(
        "service_auvknb1",
        "template_t0f1yks",
        e.target,
        "nuu6ysYJnJo-_CzbW"
      )
      .then(
        (result) => {
          setIsFormSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setTimeout(() => {
      emailjs
        .sendForm(
          "service_auvknb1",
          "template_9p6g0e1",
          e.target,
          "nuu6ysYJnJo-_CzbW"
        )
        .then(
          (result) => {
            setIsFormSubmittedToClient(true);

            toast.dismiss(toastId);

            toast.success("Successfully send email!");

            setTimeout(() => {
              navigate("/ThankYou/Y/N");
            }, 2000);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }, 2000);
    // if (isFormSubmitted) {
    //   //email to client
    //   emailjs
    //     .sendForm(
    //       "service_auvknb1",
    //       "template_9p6g0e1",
    //       e.target,
    //       "nuu6ysYJnJo-_CzbW"
    //     )
    //     .then(
    //       (result) => {
    //         setIsFormSubmittedToClient(true);

    //         setTimeout(() => {
    //           toast.dismiss(toastId);
    //         }, 2000);

    //         setTimeout(() => {
    //           toast.success("Successfully send email!");
    //         }, 3000);
    //       },
    //       (error) => {
    //         console.log(error.text);
    //       }
    //     );
    // }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setUserData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  const handleKeyDown = (e) => {
    const key = e.key;
    const isNumericKey = !isNaN(key);
    const isBackspace = key === "Backspace";
    const isPlus = key === "Add" || key === "+";

    if (e.target.id === "nricPassport") {
      if (!isNumericKey && !isBackspace) {
        e.preventDefault();
      }
    } else {
      if (!isNumericKey && !isBackspace && !isPlus) {
        e.preventDefault();
      }
    }
  };

  return (
    <React.Fragment>
      <Container className="wrapper">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={"/"} className="header-item">
                Home
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem>Contact</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <Row>
          <Col lg={5}></Col>

          <Col lg={7}>
            <p style={{ textAlign: "left", fontSize: "2.5rem" }}>
              <b>
                <i>Contact Us</i>
              </b>
            </p>
          </Col>

          <Col lg={5}></Col>

          <Col lg={3}>
            <Form onSubmit={onSubmit} ref={form}>
              <FormGroup>
                <Label for="fullName">Full Name</Label>

                <Input
                  id="fullName"
                  name="user_name"
                  type="text"
                  required
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="email">Email Address</Label>

                <Input
                  id="email"
                  name="user_email"
                  type="email"
                  required
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="telephone">Telephone</Label>

                <Input
                  id="telephone"
                  name="telephone"
                  type="text"
                  required
                  autoComplete="off"
                  onKeyDown={handleKeyDown}
                  onChange={handleInputChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="message">Message</Label>

                <Input
                  id="message"
                  name="message"
                  type="textarea"
                  required
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </FormGroup>

              <FormGroup check>
                <Input
                  id="checkbox"
                  type="checkbox"
                  style={{ cursor: "pointer" }}
                  onChange={() => setIsChecked(!isChecked)}
                />

                <Label
                  check
                  style={{
                    fontSize: "10px",
                    marginLeft: "10px",
                    width: "90%",
                    cursor: "default",
                  }}
                >
                  By submitting this form, you agree and consent to your
                  personal data which you provide to Yap Kong Heng Trading &
                  Transport Sdn Bhd.
                </Label>
              </FormGroup>

              <br />

              <Col style={{ marginLeft: 0, width: "fit-content" }}>
                <Button disabled={!isChecked}>Submit</Button>
              </Col>
            </Form>
          </Col>

          <Col lg={4}></Col>
        </Row>

        <Modal>
          <ModalHeader>Thank You</ModalHeader>

          <ModalBody>Your email has been send.</ModalBody>

          <ModalFooter>
            <Button
              type="button"
              onClick={() => {
                navigate("/ThankYou/Y/N");
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </React.Fragment>

    // <div className="wrapper contact">
    //   <h1>
    //     <i>Coming Soon..</i>
    //   </h1>

    //    <h1>Contact Us</h1>

    //     <div className="form-wrapper">
    //       <form
    //         // target="_blank"
    //         // action="https://formsubmit.co/ykhttsb@gmail.com"
    //         action="https://formsubmit.co/s.chong590@gmail.com"
    //         method="POST"
    //       >
    //         <h3>Contact Form</h3>

    //         {formItem.map((item, index) => {
    //           return (
    //             <input
    //               key={index}
    //               type={item.type}
    //               placeholder={item.placeholder}
    //               name={item.fieldName}
    //               value={item.value}
    //               required
    //               className="form-control"
    //             />
    //           );
    //         })}

    //         <textarea
    //           placeholder="Your Message"
    //           className="form-control"
    //           name="message"
    //           rows="10"
    //           required
    //         />

    //         <button
    //           type="submit"
    //           className="btn"
    //           // onClick="window.location.reload();"
    //         >
    //           Submit
    //         </button>
    //       </form>
    //     </div>

    //     <div className="map">
    //       <iframe
    //         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1991.8197448299416!2d101.60598741488155!3d3.1891157385834004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4f570770f0d1%3A0xeb45f58207278757!2s42%2C%20Jalan%20Kiara%20SD%2011%2F6%2C%20Bandar%20Sri%20Damansara%2C%2052200%20Kuala%20Lumpur%2C%20Selangor!5e0!3m2!1sen!2smy!4v1642520722266!5m2!1sen!2smy"
    //         className="map-iframe"
    //       />
    //     </div>
    // </div>
  );
};

export default Contact;
