import React, { PureComponent } from "react";

import Header from "../header";
import Footer from "../footer";
import FloatIcons from "../FloatIcons";

import "./styles.scss";
import "../../styles/_base.scss";
import { Toaster } from "react-hot-toast";

export default class Layout extends PureComponent {
  componentDidMount() {
    document.title = "Yap Kong Heng Trading & Transport Sdn Bhd";
  }

  render() {
    return (
      <div>
        <Header />
        <FloatIcons />
        <Toaster />

        <div className="Layout">{this.props.children}</div>

        <Footer />
      </div>
    );
  }
}
