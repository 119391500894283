import React from "react";

const NotFound = () => {
  return (
    <div>
      <h3>404</h3>

      <p>
        <i>Page not found...</i>
      </p>
    </div>
  );
};

export default NotFound;
