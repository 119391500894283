import React, { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Layout from "./components/layout";
import ScrollToTop from "./components/scrollToTop";

import Home from "./pages/home";
import Cranes from "./pages/cranes";
import Trailers from "./pages/trailers";
import About from "./pages/about";
import Contact from "./pages/contact";
import Booking from "./pages/booking";
import Thank from "./pages/thankyou";
import NotFound from "./pages/404/404";

import "./styles/_base.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function App() {
  return (
    <Router>
      <ScrollToTop />

      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route exact path="*" element={<NotFound />} />

          {/* <Route exact path="/ThankYou" element={<Thank />} /> */}

          <Route path="/ThankYou/:isContact/:isBooking" element={<Thank />} />

          <Route exact path="/BookNow" element={<Booking />} />

          <Route exact path="/ContactUs" element={<Contact />} />

          <Route exact path="/About" element={<About />} />

          <Route exact path="/Trailers" element={<Trailers />} />

          <Route exact path="/Cranes" element={<Cranes />} />
        </Routes>
      </Layout>
    </Router>
  );
}
