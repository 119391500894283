import "./styles.scss";
import "../../styles/_base.scss";
import "../../styles/main.scss";

import React from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Container className="wrapper">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={"/"} className="header-item">
                Home
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem>About Us</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <br />
        <br />
        <br />

        <Row>
          <Col lg={1}></Col>

          <Col lg={11}>
            <p style={{ textAlign: "left", fontSize: "2.5rem" }}>
              <b>
                <i>About Us</i>
              </b>
            </p>
          </Col>

          <Col lg={1}></Col>

          <Col lg={10}>
            <p style={{ textAlign: "justify", fontSize: "14px" }}>
              Step into the world of Yap Kong Heng Trading & Transport Sdn Bhd,
              a name synonymous with <b>reliability</b> and <b>expertise</b> in
              the crane and trailer services industry. Since our incorporation
              on November 26, 2007, we have been at the forefront of providing{" "}
              <b>top-notch</b> solutions tailored to meet the unique needs of
              our clients.
            </p>

            <p style={{ textAlign: "justify", fontSize: "14px" }}>
              With an unwavering commitment to <b>quality</b> and <b>safety</b>,
              we have established ourselves as a premier choice for those
              seeking crane and trailer services that go above and beyond
              expectations. Our journey, spanning numerous years, has allowed us
              to refine our techniques, optimize our processes, and build a
              strong foundation of <b>trust</b> with our clients.
            </p>

            <p style={{ textAlign: "justify", fontSize: "14px" }}>
              At Yap Kong Heng Trading & Transport Sdn Bhd, we understand that
              every project comes with its own set of <b>challenges</b> and{" "}
              <b>requirements</b>. That's why our <b>seasoned professionals</b>{" "}
              work closely with you to comprehend your specific needs and devise{" "}
              <b>customized</b>
              solutions that ensure <b>seamless execution</b>. Whether it's{" "}
              <b>heavy lifting</b> or <b>intricate logistics</b>, our{" "}
              <b>state-of-the-art cranes</b> and meticulously{" "}
              <b>maintained trailers</b> are geared to deliver results that
              exceed industry <b>standards</b>.
            </p>

            <p style={{ textAlign: "justify", fontSize: "14px" }}>
              What sets us apart is not just our <b>cutting-edge equipment</b>,
              but also our team's <b>expertise</b> and <b>dedication</b>. Our
              journey from 2007 to the present has been marked by continuous
              learning and <b>growth</b>, allowing us to adapt to changing
              industry <b>trends</b> and emerging <b>technologies</b>. This
              ensures that when you choose us, you're choosing a partner that
              remains at the <b>forefront of innovation</b>, guaranteeing the
              most <b>efficient</b> and <b>cost-effective solutions</b> for your
              projects.
            </p>

            <p style={{ textAlign: "justify", fontSize: "14px" }}>
              As we move forward, Yap Kong Heng Trading & Transport Sdn Bhd
              remains committed to upholding our <b>reputation</b> as a reliable
              and customer-centric service provider. Our{" "}
              <b>legacy of excellence</b> speaks volumes about our{" "}
              <b>capabilities</b>, and we look forward to the opportunity to{" "}
              <b>serve you</b> with the same level of dedication and{" "}
              <b>professionalism</b> that has been the hallmark of our company
              since its <b>inception</b>.
            </p>
          </Col>

          <Col lg={1}></Col>
        </Row>

        <Row>
          <Col lg={1}></Col>

          <Col lg={2}>
            <Button type="button" onClick={() => navigate("/Cranes")}>
              Check out our Cranes
            </Button>
          </Col>

          <br />
          <br />

          <Col lg={2}>
            <Button type="button" onClick={() => navigate("/Trailers")}>
              Check out our Trailers
            </Button>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default About;
