import "./styles.scss";
import "../../styles/_base.scss";
import "../../styles/main.scss";
import Pricing from "../../styles/sources/home/medal.png";
import Expertise from "../../styles/sources/home/practice.png";
import Customized from "../../styles/sources/home/custom.png";
import Safety from "../../styles/sources/home/safe.png";

import React, { PureComponent } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";

const App = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Container className="wrapper">
        <br />
        <br />
        <br />

        <Row>
          <Col lg={12}>
            <h1 style={{ paddingBottom: "10px", textAlign: "left" }}>
              Yap Kong Heng Trading & Transport Sdn Bhd
            </h1>

            <p style={{ fontSize: "14px" }}>
              Your Trusted Cranes and Trailers Specialist. Bringing over{" "}
              <b>two decades</b> of unparalleled expertise. <br />
              Established on November 26, 2007, our company has been diligently
              serving the needs of various industries with a commitment to
              excellence and efficiency.
            </p>
          </Col>

          <Col>
            <Button type="button" onClick={() => navigate("/About")}>
              Learn More
            </Button>
          </Col>
        </Row>

        <br />
        <br />

        <hr style={{ margin: "auto" }} />

        <br />
        <br />

        <Row className="why-wrapper">
          <Col lg={12}>
            <h1 style={{ paddingBottom: "10px" }}>Why Choose Us ?</h1>
          </Col>

          <Col lg={3} sm={12}>
            <img src={Expertise} alt="Expertise" />

            <p>Decades of Expertise</p>
          </Col>

          <Col lg={3} sm={12}>
            <img src={Pricing} alt="Pricing" />

            <p>Competitive Pricing</p>
          </Col>

          <Col lg={3} sm={12}>
            <img src={Customized} alt="Customized" />

            <p>Customized Approach</p>
          </Col>

          <Col lg={3} sm={12}>
            <img src={Safety} alt="Safety" />
            <p>Safety First Approach</p>
          </Col>
        </Row>

        <br />
        <br />

        <hr style={{ margin: "auto" }} />

        <br />
        <br />

        <Row>
          <Col>
            Leave us a message so we can understand your needs better.
            <br />
            <FontAwesomeIcon
              icon={faAnglesDown}
              bounce
              style={{ color: "#000000" }}
            />
            <br />
            <Button type="button" onClick={() => navigate("/ContactUs")}>
              Contact Us
            </Button>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default App;
