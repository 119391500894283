import "./styles.scss";
import "../../styles/_base.scss";

import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Container, Row } from "reactstrap";

function Thanks() {
  const { isContact, isBooking } = useParams();

  useEffect(() => {
    if (isContact === "undefined" || !isContact) {
      return;
    }
    if (isContact === "Y") {
      return;
    }
    return;
  }, [isContact]);

  return (
    <React.Fragment>
      <Container className="wrapper">
        <Row>
          <div style={{ textAlign: "center" }}>
            {isContact === "Y" && (
              <div className="thank-you-title">
                <h1 style={{ paddingBottom: 10, textTransform: "capitalize" }}>
                  Thank you for contacting us
                </h1>

                <h5 style={{ textAlign: "center", marginBottom: 20 }}>
                  <i>We will be in touch with you soon</i>
                </h5>

                <Link to="/">
                  <Button className="button">
                    <i>HOME</i>
                  </Button>
                </Link>
              </div>
            )}

            {(isContact === "N" || isContact === "undefined" || !isContact) && (
              <div className="thank-you-title">
                <h1>Opps</h1>
                <p>
                  <i>How did you manage to get here?</i>
                </p>
                <Link to="/">
                  <button className="button">
                    <i>home</i>
                  </button>
                </Link>
              </div>
            )}
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Thanks;
